<template>
  <Layout>
    <div class="upi-collect">
      <div class="header">
        <h1>₹ {{ amount }}</h1>
      </div>
      <div class="section">
        <h4>Pay using UPI ID</h4>
        <van-field
            class="vpa-input"
            v-model="data.vpa"
            placeholder="Please enter your UPI ID"
        />
        <p v-if="data.showError" class="error-tips">
          Please enter the correct UPI ID
        </p>
        <button class="button" @click="checkVpaVal">Verify</button>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layout/index.vue";
import {useRoute, useRouter} from "vue-router";
import {reactive} from "vue";
import {upiCollectPayment, upiVpaCheck} from "@/api/payment";
import {Toast} from "vant";

let route = useRoute();
let router = useRouter();

let data = reactive({
  vpa: "",
  showError: false,
});

// 交易数据
const amount = route.query.amount;

// 检测vpa
const checkVpaVal = () => {
  data.showError = false;
  let toast = Toast.loading({
    className: "loading",
    message: "Loading...",
    duration: 0,
    forbidClick: true,
  });

  upiVpaCheck({customerVpa: data.vpa}).then((response) => {
    if (response.data && response.data.status === "SUCCESS") {
      data.showError = false;
      // 发起支付
      intentPay(data.vpa);
      toast.clear();
    } else {
      data.showError = true;
      toast.clear();
    }
  });
};

// 发起支付
let intentPay = (vpaCode) => {
  upiCollectPayment({payerVpaID: vpaCode}).then((response) => {
    if (
        200 === response.code &&
        response.data &&
        (response.data.status === "SUCCESS" || response.data.status === "PENDING")
    ) {
      // 跳下个页面-倒计时轮询结果页
      router.replace({
        path: "/upiCountdown",
        query: {
          amount: amount,
          upiId: vpaCode,
          originalOrderId: response.data.originalOrderId,
        },
      });
    } else {
      // 失败跳转失败页面
      router.replace({
        path: "/paymentResult",
        query: {
          result: "FAILURE",
          type: "UPI Collect",
          amount: amount,
        },
      });
    }
  });

};
</script>

<style scoped lang="scss" type="text/scss">
.bottom {
  width: 92%;
  position: absolute;
  bottom: 5%;
  color: #999999;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;

  button {
    float: right;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 48px;
  }
}

.upi-collect {
  width: 100%;
  height: 100%;

  .header {
    text-align: center;
    overflow: hidden;

    h1 {
      margin: 0 0 6%;
      color: #333333;
    }

    p {
      color: #666666;
      margin: 2% 0 4%;
    }
  }

  .section {
    color: #333333;

    .vpa-input {
      font-size: 1.2rem;
      margin: 16px 0;
      border: 1px solid #eee;
      border-radius: 4px;
    }

    .error-tips {
      color: rgb(203, 7, 7);
    }
  }

  .button {
    float: right;
    width: 24%;
    height: 30px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 15px;
    opacity: 1;
  }
}
</style>
